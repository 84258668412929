<!--<template>
  <div class="h-100 d-flex flex-column overflow-hidden" id="about-page">
    <div id="about-image" class="d-block d-sm-none" />
    <div class="container my-sm-auto overflow-auto" id="about-scroll">
      <div class="d-flex flex-column mt-4">
      <div>
          <div class="d-flex flex-column">
            <div class="mb-2" v-fade-in="{ div: 'about-scroll', delay: 200 }">
              <h5 class="text-uppercase logo-font">Who we are</h5>
              <p class=" ">
                {{ texts.about.who }}
              </p>
            </div>

            <div class="d-flex justify-content-center">
              <div
                v-for="(p, j) in people"
                :key="j"
                v-fade-in="{ div: 'about-scroll', delay: 200 + (j + 1) * 240 }"
                class="flex-column mx-2 my-auto ml-sm-5"
              >
                <a :href="p.link" class="mx-auto team-guy text-center">
                  <img
                    :src="`images/about/${p.name
                      .split(' ')[0]
                      .toLowerCase()}.jpg`"
                    class="team-image mb-2"
                    :alt="p.name"
                  />
                  <p class="m-0">
                    {{ p.name }}
                    <i
                      class="fab fa-linkedin cursor-pointer text-white rounded m-0 d-none d-sm-inline"
                    />
                  </p>
                  <p class="small m-0" style="font-size: 0.7em; opacity: 0.7">
                    {{ p.qual }}
                  </p>
                </a>

                <p
                  class="text-center"
                  style="max-width: 9em; font-weight: 200; font-size: 0.8em"
                >
                  <small>
                    {{ p.des }}
                  </small>
                </p>
              </div>
            </div>



            <div
              class="  "
              v-fade-in="{ div: `about-scroll`, delay: 400, offset: 0.9 }"
            >
              <h5 class="text-uppercase logo-font">What we do</h5>
              <p class=" ">
                {{ texts.about.what }}
              </p>
            </div>
          </div>
        </div>

        <p class="small m-0 mt-1 text-center mt-auto d-md-none d-block"
          style="color:gray;"
        >
              <i class="fas fa-map-marker-alt m-0 mr-1 "></i>
              {{ texts.footer.address }}
        </p>

      </div>
    </div>


    <p class="small m-0 mt-1 text-white text-center mt-auto d-none d-md-block">
      <i class="fas fa-map-marker-alt m-0 mr-1 text-white"></i>
      {{ texts.footer.address }}
    </p>

  </div>
</template> -->
<template>
  <div class="h-100 d-flex flex-column" id="aboutus-page">
    <div class="pt-5 text-center">
      <p class="mt-2 title">What we do?</p>
      <div class="container content">
        <span>We help both the buyers and sellers of insurance comprehensively identify financial needs in general and insurance needs in particular. Our API-first proprietary algorithm maps the financial profile of the individuals enabling us to find gaps. Our algorithm suggests financial products to fill these gaps appropriately thus protecting you well throughout the lifetime</span>
      </div>
    </div>
    <div class="pt-3">
      <div class="pt-5 text-center">
        <p class="mt-2 title">Why protectmewell?</p>
        <div class="container content">
          <span>We strongly believe that everyone deserves to fuller life and being financially well-covered plays a significant role in achieving it. At <a style="color: #293173;" href="https://protectmewell.com"><b>ProtectMeWell.com</b></a>, we want to be the enabler by helping you answer the first questions around buying financial products i.e what type of products to buy, for how long, and how much of it. And we do it in an unbiased, scientific, and non-intrusive fashion!</span>
        </div>
      </div>
    </div>
    <div class="pt-3 team-sec">
      <div class="pt-5 text-center">
        <p class="mt-2 title">Know the team</p>
        <div class="pt-5 mt-5">
          <div class="row col-12" style="justify-content: space-around;">
              <div
                v-for="(p, j) in people"
                :key="j"
                v-fade-in="{ div: 'about-scroll', delay: 200 + (j + 1) * 240 }"
                class="col-md-6 team-content"
              >
                <a :href="p.link" class="mx-auto team-guy text-center">
                  <img
                    :src="`images/about/${p.name
                      .split(' ')[0]
                      .toLowerCase()}.png`"
                    class="team-image mb-2"
                    :alt="p.name"
                  />
                  <div style="margin-top: -65px;">
                    <p class="m-0" style="color: #293173;font-size:15px;">
                      {{ p.name }}
                    </p>
                    <p class="m-0" style="font-size:12px;color: #000000;">
                      {{ p.des }}
                    </p>
                    <p class="content m-0" style="letter-spacing: 0.01em;color: #293173;padding-top: 14px;font-size:15px;font-weight: normal;">{{ p.desc }}</p>
                  </div>
                </a>
                <p class="m-0 d-flex" style="justify-content: center;position: relative;top: -15px;">
                    <a :href="p.link" target="_blank"><img src='images/footer/Group 12.png' height="25px"/></a>
                    <a :href="p.tlink" target="_blank"><img src='images/footer/Ellipse 50.png' style="height:25px;margin-left:10px" /></a>
                </p>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="pt-5 mt-4 pb-2 brand-sec">
      <div class="pt-5 text-center">
        <p class="mt-2 title">As seen on</p>
        <div class="container d-flex pt-4">
          <div class="col-3">
            <a href="https://www.the-digital-insurer.com/dynamic-insurtech-maps/india/" target="_blank"><img src="images/footer/image 17.png"/></a>
          </div>
          <div class="col-3 pl-5">
            <a href="https://web-assets.bcg.com/23/25/6f7a462249139fbe197f8e420ae0/bcg-insurtech-report-v33.pdf" target="_blank"><img  src="images/footer/image 18.png"/></a>
          </div>
          <div class="col-3 pl-5">
            <a href="https://www.asiainsurancereview.com/News/View-NewsLetter-Article/id/61225/Type/eDaily" target="_blank"> <img src="images/footer/image 19.png"/></a>
          </div>
          <div class="col-3 pl-5">
            <a href="https://protectmewell.medium.com/best-protection-from-lifes-uncertainties-before-it-s-too-late-a819f9498bee" target="_blank"><img src="images/footer/image 21.png"/></a>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5 mt-4 pb-4 brand-sec">
      <div class="pt-5 text-center">
        <p class="mt-2 title">Associated with</p>
        <div class="container d-flex pt-4">
          <img class="col-4" src="images/footer/image 11.png"/>
          <img class="col-4 pl-3" src="images/footer/image 15.png"/>
          <img class="col-4 pl-5" src="images/footer/image 14.png"/>
        </div>
      </div>
    </div>
    <div class="pt-5 ml-5 mr-5">
    <p class="mt-2 title text-left" style="color: #000000;font-size:25px;">Contact Us</p>
      <Footer :inverted="true" />
    </div>
  </div>
</template>

<script type='text/javascript'> (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })(); </script>

<script>
import texts from "../texts";
import Footer from "../components/special/Footer";
export default {
  components: { Footer },
  data() {
    return {
      texts,
      people: [
        {
          section: "Who We Are",
          text: texts.about.who,
          name: "Sumit Ramani",
          des: "CEO & Co-founder",
          qual: "FIAI, FIA, BTech",
          link: "https://www.linkedin.com/in/sumitramani/",
          tlink: 'https://twitter.com/ramanisumit',
          desc: 'Computer Science engineer turned qualified actuary building robust financial models since 2008 across the globe. Passionate about personal finance'
        },
        {
          section: "What We Do",
          text: texts.about.what,
          name: "Namit Kothari",
          des: "CTO",
          link: "https://www.linkedin.com/in/knamit01/",
          tlink: 'https://twitter.com/knamit01',
          desc: 'Electrical engineer and tech-geek building scalable technological solutions since 2006. Passionate about tech, regulations, and programming.'
        },
        /*{
          section: "",
          text: "",
          name: "Mahavir Chopra",
          des: "Advisor",
          extra: ` (Currently serving as the Founder of Beshak.org, an independent consumer awareness platform for Insurance)`,
          link: "https://in.linkedin.com/in/mahavir/",
        },*/
      ],
    };
  },
};
</script>

<style scoped>
#about-page i {
  color: gray;
}

#about-page h5 {
  color: var(--darkblue);
}

#about-page {
  background: white;
}
@media screen and (min-width: 575px) {
  #about-page {
    /* background-image: url('/images/landing/whoweare.png');
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.3); */
    background: none;
    text-align: left;
  }
  #about-image {
    display: none;
  }
  #about-page p,
  #about-page a {
    color: white;
  }
  #about-page h5 {
    color: var(--yellow);
  }
  #about-image {
    display: none;
  }
}

#about-image {
  height: 520px;
  background-image: var(--about-image);
  background-size: cover;
}

.team-image {
  --tsize: 101px;
  width: var(--tsize);
  height: var(--tsize);
  border-radius: 50%;
  background-color: lightgray;
  border: 2px solid white;
  cursor: pointer;
  object-fit: cover;
  box-shadow: 0 0 8px var(--bluegray);
}
.team-guy {
  font-size: 0.8em;
}
.team-image:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.411);
}
.team-image:active {
  box-shadow: unset;
}

.fa-linkedin {
  font-size: 1.2em;
}
.fa-linkedin:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.411);
}
@media screen and (min-width: 576px) {
  .team-image {
    box-shadow: unset;

    --tsize: 108px;
    width: var(--tsize);
    height: var(--tsize);
  }
  .team-guy {
    font-size: 1em;
  }
}

#aboutus-page .title {
  font-family: Roboto;
  font-weight: 500;
  font-size: 40px;
  line-height: 180%;
  color: #293173;
  text-transform: uppercase;
}

#aboutus-page .content {
    background: #EAF3F7;
    border-radius: 42px;
    padding: 30px;
    white-space: -webkit-nowrap;
    max-width: 950px;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    opacity: 0.8;
    line-height: 180%;
    margin-top: 30px;
}

#aboutus-page .team-image {
  /* --tsize: 101px;
  width: var(--tsize);
  height: var(--tsize); */
  border-radius: initial !important;
  cursor: pointer;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.15));
  border: none !important;
  background: transparent !important;
  position: relative;
  top: -70px;
  box-shadow: unset;
  object-fit: contain;
  height: 150px;
}

.team-sec .team-content {
  max-width: 500px;
  height: 300px;
  background: #EAF3F7;
  box-shadow: 0px 4px 10px rgba(41, 49, 115, 0.15);
  border-radius: 42px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .team-sec .team-content {
    margin-bottom: 120px;
  }
}

.team-guy p{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #000000;
}
.team-guy:hover {
  text-decoration: none;
}
.fa-linkedin {
    color: #4D98B9;
    font-size: 20px;
    border-radius: 50% !important;
    position: relative;
    top: -18px;
}
.brand-sec img {
    width: 220px;
    height: 60px;
    object-fit: scale-down;
}
@media screen and (max-width: 991px) {
  .brand-sec img {
    width: 80px;
  }
}
</style>
